export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	DASHBOARD = 'dashboard',
	CONTENTS = 'contents',
	PROJECTS = 'projects',

	COMPONENT_CATEGORIES = 'component-categories',
	COMPONENTS = 'components',
	COMPONENT_ITEMS = 'component-items',
	PARAMETER_CATEGORIES = 'parameter-categories',
	PARAMETERS = 'parameters',

	USERS = 'users',
	ADMINS = 'admins',
	LOGIN_HISTORY = 'login-history',

	ROLES = 'roles',
	MAIL_TEMPLATES = 'mail-templates',
	GLOBAL_SETTINGS = 'global-settings',
}
