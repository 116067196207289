import { LoginValues } from '@/pages/unauthenticated/Login';
import { getErrorCodes } from '@/utils/utilities';
import httpClient from './httpClient';

type TokenResponse = {
	accessToken: string;
	refreshToken: string;
};

abstract class UnauthenticatedApi {
	static login = async ({ email, password }: LoginValues) => {
		const response = await httpClient.post<TokenResponse>('auth/login', {
			email,
			password,
		});

		return response;
	};

	static refreshToken = async (refreshToken: string) => {
		const response = await httpClient.post<TokenResponse>('auth/refresh', {
			refreshToken,
		});
		return response;
	};

	static requestResetPassword = async (email: string) => {
		try {
			await httpClient.post('auth/reset_password/create', {
				email,
				redirectUrl: `${import.meta.env.VITE_APP_URL}set-password`,
			});
			return 3;
		} catch (error) {
			const message = getErrorCodes(error);
			return { message };
		}
	};

	static resetPassword = async (hash: string, password: string) => {
		try {
			await httpClient.post('auth/reset_password/process', {
				hash,
				password,
			});
			return 3;
		} catch (error) {
			const message = getErrorCodes(error);

			return { message };
		}
	};
}

export default UnauthenticatedApi;
