import { QueryKey } from '@/configs/queryKeys';
import { Translations, UUID } from '@/types/types';
import EntityApi from './EntityApi';

export enum MailTemplateType {
	ADMIN_NEW_ACCOUNT,
	CLIENT_NEW_ACCOUNT,
	CONTACT_REQUEST,
	COPY_OF_PROJECT,
	COPY_OF_YOUR_PROJECT,
	FORGOT_PASSWORD,
	PROJECT_SENT,
	REGISTER_MAIL,
	VERIFY_ACCOUNT,
	VERIFY_EMAIL_CHANGE,
}

export interface MailTemplate {
	id: UUID;
	subject: string;
	content: string;
	variables: string;
	type: MailTemplateType;
	translations: Translations<{
		subject: string;
		content: string;
	}>;
}

class MailTemplateApi extends EntityApi<MailTemplate> {
	constructor() {
		super(QueryKey.mailTemplates);
	}
}

export default new MailTemplateApi();
