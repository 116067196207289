import { flatten } from 'lodash';
import { isAxiosError } from 'axios';
import { ApiError, AppError, ErrorCode } from '@/types/ErrorCodes';
import { formatDistance } from 'date-fns';
import { ProjectStatus } from '@/api/ProjectApi';
import { Language } from '@/types/types';
import { ConnectorType } from '@/api/ComponentApi';
import { MailTemplateType } from '@/api/MailTemplateApi';
import moment from 'moment';
import polish from 'date-fns/locale/pl';
import APP_CONFIG from '@/configs/appConfig';

export const priceFormatter = new Intl.NumberFormat('PL-pl', {
	style: 'currency',
	currency: 'PLN',
	currencyDisplay: 'code',
});

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

export function randomDate(start: Date, end: Date) {
	return moment(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	).toDate();
}

export function formatDateForApi(date: moment.MomentInput) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('HH:mm');
}

export function sortByDate(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function sortByName(
	nameA = '',
	nameB = '',
	order: 'asc' | 'desc' = 'asc'
) {
	return order === 'asc'
		? nameA.localeCompare(nameB)
		: nameB.localeCompare(nameA);
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isAppError = (error: unknown): error is AppError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as AppError).message
	);
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export const fontColorForBackground = (backgroundColorHex = '#ffffff') => {
	return [
		0.299 * parseInt(backgroundColorHex.slice(1, 3), 16),
		0.587 * parseInt(backgroundColorHex.slice(3, 5), 16),
		0.114 * parseInt(backgroundColorHex.slice(5, 7), 16),
	].reduce((a, b) => a + b) /
		255 >
		0.65
		? '#495057'
		: '#ffffff';
};

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return `-`;
	return firstName || lastName;
};

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatDateFromNow(date: Date) {
	return formatDistance(date, new Date(), {
		locale: polish,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function projectStatusToName(status: ProjectStatus) {
	switch (status) {
		case ProjectStatus.NOT_SENT:
			return 'Nie wysłano';
		case ProjectStatus.SENT:
			return 'Wysłano';
		case ProjectStatus.ARCHIVED:
			return 'Archiwum';
	}
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function getConnectorTypeText(connectorType: ConnectorType) {
	switch (connectorType) {
		case ConnectorType.L:
			return 'Łącznik L';
		case ConnectorType.T:
			return 'Łącznik T';
		case ConnectorType.X:
			return 'Łącznik X';
		case ConnectorType.I:
			return 'Łącznik I';
		case ConnectorType.SQUARE:
			return 'Łącznik kwadratowy';
	}
}

const onlyDigitsReg = /^\d+$/;

export function getComponentIndiciesFromText(text: string) {
	return [
		...new Set(
			text
				.replace(/\s/g, '')
				.split(',')
				.filter((idx) => onlyDigitsReg.test(idx))
		),
	];
}

export function mapMailTemplateTypeToName(type: MailTemplateType) {
	switch (type) {
		case MailTemplateType.ADMIN_NEW_ACCOUNT:
			return 'Rejestracja admina';
		case MailTemplateType.CLIENT_NEW_ACCOUNT:
			return 'Utworzenie klienta';
		case MailTemplateType.CONTACT_REQUEST:
			return 'Prośba o kontakt';
		case MailTemplateType.COPY_OF_PROJECT:
			return 'Zduplikowano projekt';
		case MailTemplateType.COPY_OF_YOUR_PROJECT:
			return 'Udostępnij projekt';
		case MailTemplateType.FORGOT_PASSWORD:
			return 'Reset hasła';
		case MailTemplateType.PROJECT_SENT:
			return 'Wysłano projekt';
		case MailTemplateType.REGISTER_MAIL:
			return 'Rejestracja klienta';
		case MailTemplateType.VERIFY_ACCOUNT:
			return 'Weryfikacja konta';
		case MailTemplateType.VERIFY_EMAIL_CHANGE:
			return 'Potwierdzenie zmiany adresu';

		default:
			return 'Nieznany szablone';
	}
}
