import { ReactNode } from 'react';
import {
	MantineProvider as Mantine,
	MantineThemeOverride,
	NumberInput,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { SpinnerLoader } from '@/components/ui/Loading';
import { DatesProvider } from '@mantine/dates';
import mantineStyles from '@/assets/scss/custom/mantine.module.scss';
import 'dayjs/locale/pl';

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	const components: MantineThemeOverride['components'] = {
		JsonInput: {
			classNames: {
				input: mantineStyles.jsonInputInput,
			},
		},
		Select: {
			classNames: {
				input: 'form-control',
				item: mantineStyles.selectItem,
				rightSection: mantineStyles.selectRightSection,
				nothingFound: mantineStyles.selectNothingFound,
				itemsWrapper: mantineStyles.selectItemsWrapper,
			},
			defaultProps: {
				rightSectionWidth: 30,
				radius: 4,
			},
		},
		NumberInput: {
			defaultProps: {},
			classNames: {
				input: 'form-control',
			},
		},
		MultiSelect: {
			classNames: {
				searchInput: 'form-control',
				nothingFound: mantineStyles.selectNothingFound,
				dropdown: mantineStyles.multiselectDropdown,
				itemsWrapper: mantineStyles.selectItemsWrapper,
				value: mantineStyles.multiselectValue,
				input: mantineStyles.multiselectInput,
				item: mantineStyles.selectItem,
				rightSection: mantineStyles.selectRightSection,
			},
			defaultProps: {
				rightSectionWidth: 30,
				radius: 4,
			},
		},
		LoadingOverlay: {
			defaultProps: {
				loader: <SpinnerLoader />,
			},
		},
		Slider: {
			classNames: {
				markLabel: mantineStyles.sliderMarkLabel,
			},
			defaultProps: {
				color: '#2563eb',
				labelTransition: 'fade',
				labelTransitionDuration: 250,
			},
		},
		RangeSlider: {
			defaultProps: {
				color: '#2563eb',
				labelTransition: 'fade',
				minRange: 1,
				labelTransitionDuration: 250,
			},
		},
		Avatar: {
			classNames: {
				placeholder: mantineStyles.avatarPlaceholder,
			},
		},
		Popover: {
			defaultProps: {
				position: 'bottom-end',
				shadow: 'md',
				radius: '.4rem',
			},
			classNames: {
				dropdown: `${mantineStyles.popoverDropdown} popover`,
			},
		},
		Pagination: {
			classNames: {
				control: mantineStyles.paginationControl,
				dots: mantineStyles.paginationDots,
			},
			defaultProps: {
				spacing: 0,
			},
		},
		MonthPicker: {
			defaultProps: {
				withCellSpacing: false,
				size: 'xs',
			},
			classNames: {
				calendarHeader: mantineStyles.monthPickerHeader,
				monthsListCell: mantineStyles.monthPickerMonthsListCell,
			},
		},
		ScrollArea: {
			defaultProps: {
				scrollbarSize: 4,
			},
		},
		Tooltip: {
			defaultProps: {
				withArrow: true,
			},
			classNames: {
				tooltip: 'tooltip',
			},
		},
	};

	return (
		<Mantine
			theme={{
				fontFamily: 'Poppins, sans-serif',
				components,
			}}
		>
			<ModalsProvider>
				<DatesProvider settings={{ locale: 'pl' }}>{children}</DatesProvider>
			</ModalsProvider>
		</Mantine>
	);
};
