const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	HEADER_HEIGHT: 70,
	TABLE_PAGE_SIZES: [15, 50, 100],
	DEFAULT_LANGUAGE: 'PL',
	MAX_COMPONENT_ITEM_PRICE: 100_000,
	MAX_COMPONENT_ITEM_LENGTH: 10_000,
	MAX_COMPONENT_ITEM_HEIGHT: 100,
	MAX_COMPONENT_ITEM_THICKNESS: 100,
	ADMIN_DEFAULT_ROLE_ID: '14de099f-1aa1-4d2c-946e-b8dc20442b9a',
} as const;

export default APP_CONFIG;
