import { Admin } from '@/types/user';
import { QueryKey } from '@/configs/queryKeys';
import { CreateManyResponse } from '@/types/types';
import axios, { AxiosResponse } from 'axios';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

class AdminApi extends EntityApi<Admin> {
	constructor() {
		super(QueryKey.admins);
		this.createMany = this.createMany.bind(this);
	}

	async createMany(admins: Partial<Admin>[]): Promise<CreateManyResponse> {
		const client = axios.create({
			...httpClient.defaults,
			validateStatus: () => true,
		});

		const requests: Promise<AxiosResponse<Admin>>[] = admins.map((cli) =>
			client.post(this.queryKey, cli)
		);

		const responsens = await Promise.all(requests);

		return admins.reduce<CreateManyResponse>(
			(prev, current, index) =>
				responsens[index].status === 201
					? { ...prev, passed: [...prev.passed, responsens[index].data.email] }
					: {
							...prev,
							rejected: [...prev.rejected, current.email!],
					  },
			{
				passed: [],
				rejected: [],
			}
		);
	}
}

export default new AdminApi();
