import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store/index';

interface SliceState {
	loading: boolean;
	errorCode: string;
}

const initialState: SliceState = {
	loading: false,
	errorCode: '',
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.errorCode = action.payload;
		},
	},
});

export const { setLoading, setErrorCode } = appSlice.actions;
export const selectAppState = (state: RootState) => state.app;
export default appSlice.reducer;
