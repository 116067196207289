import React, { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Form, Label, Input, FormFeedback } from 'reactstrap';
import { useAuth } from '@/providers/AuthProvider';
import { getErrorMessage, ErrorCode } from '@/types/ErrorCodes';
import { validatePassword } from '@/utils/utilities';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { LoadingOverlay } from '@mantine/core';
import AdminApi from '@/api/AdminApi';

const initialValues = {
	password: '',
	repeatPassword: '',
};

export const SetPasswordModal: React.FC = () => {
	const { upatedUser } = useAuth();
	const user = useAuth().user!;

	const [opened, { open, close }] = useDisclosure(false);

	useEffect(() => {
		if (user && !user?.hasOwnPassword) {
			open();
		}
	}, [user]);

	const form = useForm({
		initialValues,
		validate: {
			password: (value: string, values: typeof initialValues) => {
				if (value !== values.repeatPassword)
					return 'Oba hasła muszą być takie same';
				if (!validatePassword(value))
					return getErrorMessage([ErrorCode.INVALID_PASSWORD]);
				return null;
			},
			repeatPassword: (value: string, values: typeof initialValues) => {
				if (value !== values.password) return 'Oba hasła muszą być takie same';
				if (!validatePassword(value))
					return getErrorMessage([ErrorCode.INVALID_PASSWORD]);
				return null;
			},
		},
	});

	const updateMutation = useMutation(AdminApi.update, {
		onSuccess: () => {
			upatedUser({ hasOwnPassword: true });
			close();
		},
	});

	const handleSubmit = ({ password }: typeof initialValues) => {
		updateMutation.mutate({ id: user.id, password, hasOwnPassword: true });
	};

	return (
		<Modal size="md" centered isOpen={opened}>
			<Form className="form-horizontal" onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={updateMutation.isLoading} />
				<div className="modal-header">
					<h5 className="modal-title mt-0" id="myModalLabel">
						Ustaw nowe hasło
					</h5>
				</div>
				<div className="modal-body">
					<p className="mb-3">
						To konto nie posiada jeszcze własnego hasła. Ustaw nowe hasło teraz.
						Hasło musi składać się z co najmniej 6 znaków, w tym jednej cyfry.
					</p>
					<div className="mb-3">
						<Label className="form-label">Hasło</Label>
						<Input
							required
							type="password"
							className="form-control"
							placeholder="Wpisz hasło"
							minLength={6}
							{...form.getInputProps('password')}
							invalid={!!form.errors.password}
						/>
						<FormFeedback type="invalid">{form.errors.password}</FormFeedback>
					</div>
					<div>
						<Label className="form-label">Powtórz hasło</Label>
						<Input
							required
							type="password"
							className="form-control"
							placeholder="Wpisz hasło"
							minLength={6}
							{...form.getInputProps('repeatPassword')}
							invalid={!!form.errors.repeatPassword}
						/>
						<FormFeedback type="invalid">
							{form.errors.repeatPassword}
						</FormFeedback>
					</div>
				</div>
				<div className="modal-footer">
					<button type="submit" className="btn btn-primary">
						Ustaw
					</button>
				</div>
			</Form>
		</Modal>
	);
};
