import { QueryKey } from '@/configs/queryKeys';
import { Attachment, Translations, UUID } from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { ComponentForm } from '@/pages/authenticated/components/CreateComponent';
import { ComponentItem } from './ComponentItemApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export enum ConnectorType {
	L,
	T,
	X,
	I,
	SQUARE,
}

export enum ComponentType {
	LAMP,
	CONNECTOR,
	ACCESSORY,
	SLING,
}

type ComponentPayloadWithFile = ComponentForm & {
	id?: UUID;
	image?: File;
};

export interface Component {
	id: UUID;
	name: string;
	componentCategory: ComponentCategory;
	type: ComponentType;
	connectorType: ConnectorType | null;
	componentItems: ComponentItem[];
	componentItemsCount: number;
	description: string;
	translations: Translations<{
		name: string;
		description: string;
	}>;
	image?: Attachment;
	isLampConnectable?: boolean;
	defaultComponentItem?: Omit<ComponentItem, 'component'>;
}

class ComponentApi extends EntityApi<Component> {
	constructor() {
		super(QueryKey.components);
		this.updateWithImage = this.updateWithImage.bind(this);
		this.createWithImage = this.createWithImage.bind(this);
	}

	async updateWithImage(payload: Partial<ComponentPayloadWithFile>) {
		let image: string | undefined = undefined;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.patch<Component>(
			`${this.queryKey}/${payload.id}`,
			{ ...payload, image }
		);

		return response.data;
	}

	async createWithImage(payload: ComponentPayloadWithFile) {
		let image: string | undefined = undefined;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.post<Component>(this.queryKey, {
			...payload,
			defaultComponentItem: payload.defaultComponentItem,
			image,
		});
		return response.data;
	}
}

export default new ComponentApi();
