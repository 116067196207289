import React, { useContext, ReactNode, useEffect } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { ForeignLanguage, Language } from '@/types/types';
import { isDefaultLanguage } from '@/utils/utilities';
import APP_CONFIG from '@/configs/appConfig';
import httpClient from '@/api/httpClient';
import LANGUAGES from '@/configs/languages';

interface Context {
	language: Language;
	setLanguage: (lang: Language) => void;
	getEntityText: <
		TObj extends {
			translations: Record<
				ForeignLanguage,
				Partial<Record<keyof TObj, string>>
			> | null;
		}
	>(
		obj: TObj,
		key: keyof TObj
	) => string;
}

const ContentContext = React.createContext<Context>(null!);

export const useLanguage = () => useContext(ContentContext);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
	const [language, setLanguage] = useLocalStorage<Language>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});

	useEffect(() => {
		httpClient.defaults.headers['Accept-Language'] = LANGUAGES[language].code;
	}, [language]);

	const getEntityText: Context['getEntityText'] = (obj, key) => {
		if (isDefaultLanguage(language)) return obj[key] as string;

		return !!obj.translations &&
			!!obj.translations[language] &&
			!!obj.translations[language][key]
			? (obj.translations[language][key] as string)
			: (obj[key] as string);
	};

	return (
		<ContentContext.Provider
			value={{
				language,
				setLanguage,
				getEntityText,
			}}
		>
			{children}
		</ContentContext.Provider>
	);
};
