export const QueryKey = {
	content: 'content',
	projects: 'projects',
	componentCategories: 'component-categories',
	components: 'components',
	componentItems: 'component-items',
	parameterCategories: 'parameter-categories',
	parameters: 'parameters',
	admins: 'admins',
	loginHistory: 'logins-history',
	users: 'users',
	roles: 'roles',
	mailTemplates: 'mail-templates',
	globalSettings: 'global-settings',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
