import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useTypedDispatch } from '@/store/hooks';
import { setLoading, setErrorCode } from '@/store/slices/appSlice';
import { AppPaths } from '@/configs/appPaths';
import error from '@/assets/images/error-img.png';

interface Props {
	httpCode: string;
}

export const ErrorPage: React.FC<Props> = ({ httpCode }) => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();

	const back = () => {
		dispatch(setLoading(false));
		dispatch(setErrorCode(''));
		navigate(`/${AppPaths.PROJECTS}`);
	};

	return (
		<div className="account-pages">
			<Container>
				<Row>
					<Col lg="12">
						<div className="text-center mb-4">
							<h1 className="display-2 font-weight-medium">
								{formatErrorCodeHeader(httpCode)}
							</h1>
							<h4 className="text-uppercase">{errorMessage(httpCode)}</h4>
							<div className="mt-4 text-center">
								<button onClick={back} className="btn btn-primary ">
									Przejdź do ekranu głownego
								</button>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="8" xl="6">
						<div>
							<img src={error} alt="" className="img-fluid" />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

function errorMessage(httpCode: string) {
	switch (httpCode) {
		case '404':
			return 'Nie odnaleziono żądanego zasobu';
		case '':
			return 'Strona, na którą próbujesz wejść nie istnieje';
		default:
			return 'Nastąpił nieoczekiwany błąd';
	}
}

function formatErrorCodeHeader(httpCode: string) {
	const code = httpCode || '404';
	return code
		.split('')
		.map((char, index) =>
			char === '0' ? (
				<i key={index} className="bx bx-buoy bx-spin text-primary display-3" />
			) : (
				char
			)
		);
}
