import {
	useContext,
	ReactNode,
	useLayoutEffect,
	createContext,
	useState,
} from 'react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { Admin } from '@/types/user';
import { LoginValues, SubmitStatus } from '@/pages/unauthenticated/Login';
import { useQueryClient } from '@tanstack/react-query';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/httpClient';
import Cookies from 'js-cookie';

interface TokenPayload extends JwtPayload {
	user: Admin;
}

interface Context {
	user: Admin | null;
	changeUser: (newUser?: Admin | null) => void;
	upatedUser: (data: Partial<Admin>) => void;
	login: (values: LoginValues) => Promise<number>;
	logout: () => void;
}

const STORAGE_KEY = 'refreshToken';

const AuthContext = createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [user, setUser] = useLocalStorage<Admin | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [sessionRefreshToken, setSessionRefreshToken, clearSessionToken] =
		useSessionStorage<string | undefined>({
			key: STORAGE_KEY,
			defaultValue: Cookies.get(STORAGE_KEY),
			getInitialValueInEffect: false,
		});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken(sessionRefreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			logout();
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			const remember = !!Cookies.get(STORAGE_KEY);

			setAuth(decoded, accessToken, refreshToken, remember);
		} catch (error: any) {
			console.error(error);
			if (error.message !== 'Request aborted') {
				setUser(null);
				eradicateRefreshToken();
			}
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload, refresh: string) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async ({ email, password, remember }) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			setAuth(decoded, accessToken, refreshToken, remember);

			return SubmitStatus.SUCCESS;
		} catch (error) {
			console.error(error);
			return SubmitStatus.ERROR;
		}
	};

	const logout: Context['logout'] = () => {
		queryClient.cancelQueries();
		queryClient.clear();
		eradicateRefreshToken();
		setUser(null);
		httpClient.defaults.headers.common['Authorization'] = '';
		clearTimeout(refreshTimeout);
	};

	const eradicateRefreshToken = () => {
		clearSessionToken();
		Cookies.remove(STORAGE_KEY);
	};

	const saveRefreshToken = (refreshToken: string) => {
		setSessionRefreshToken(refreshToken);
		Cookies.set(STORAGE_KEY, refreshToken, {
			secure: true,
			expires: 360,
		});
	};

	const setAuth = (
		decoded: TokenPayload,
		accessToken: string,
		refreshToken: string,
		remember = true
	) => {
		const { user } = decoded;

		if (user.userType !== 'admin') throw new Error('User is not admin');

		setAuthHeader(accessToken);

		if (remember) saveRefreshToken(refreshToken);
		else setSessionRefreshToken(refreshToken);

		refreshTokenWhenExpire(decoded, refreshToken);

		setUser(user);
	};

	const changeUser: Context['changeUser'] = (newUser = null) =>
		setUser(newUser);

	const upatedUser: Context['upatedUser'] = (payload) =>
		setUser((prev) => (prev ? { ...prev, ...payload } : null));

	return (
		<AuthContext.Provider
			value={{
				user,
				changeUser,
				upatedUser,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
