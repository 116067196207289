import React from 'react';
import { Login } from '@/pages/unauthenticated/Login';
import { ResetPassword } from '@/pages/unauthenticated/ResetPassword';
import { SetNewPassword } from '@/pages/unauthenticated/SetNewPassword';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppPaths } from '@/configs/appPaths';

export const UnauthenticatedApp: React.FC = () => {
	return (
		<Routes>
			<Route path={AppPaths.LOGIN} element={<Login />} />
			<Route path={AppPaths.RESET_PASSWORD} element={<ResetPassword />} />
			<Route
				path={`${AppPaths.SET_PASSWORD}/:token`}
				element={<SetNewPassword />}
			/>
			<Route path="*" element={<Navigate to={`/${AppPaths.LOGIN}`} />} />
		</Routes>
	);
};
