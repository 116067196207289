import { PLFlag, GBFlag, DEFlag, FRFlag, CZFlag } from 'mantine-flagpack';

const LANGUAGES = {
	PL: {
		name: 'Polski',
		code: 'pl',
		flag: PLFlag,
	},
	GB: {
		name: 'English',
		code: 'en',
		flag: GBFlag,
	},
	DE: {
		name: 'Deutsche',
		code: 'de',
		flag: DEFlag,
	},
	FR: {
		name: 'Français',
		code: 'fr',
		flag: FRFlag,
	},
	CZ: {
		name: 'Čeština',
		code: 'cs',
		flag: CZFlag,
	},
} as const;

export default LANGUAGES;
