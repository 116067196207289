import { QueryKey } from '@/configs/queryKeys';
import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	Attachment,
	UUID,
} from '@/types/types';
import { Admin, User } from '@/types/user';
import { ComponentItem } from './ComponentItemApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { formatDateForApi } from '@/utils/utilities';
import { omitBy, trim } from 'lodash';
import EntityApi from './EntityApi';
import httpClient from './httpClient';
import moment from 'moment';

export enum ProjectStatus {
	NOT_SENT,
	SENT,
	ARCHIVED,
}

export interface ProjectNote {
	id: UUID;
	project: Project;
	createdAt: string;
	updatedAt: string;
	note: string;
	admin: Admin;
}

export interface ProjectOptions {
	currentStep: number;
	addedComponentItems: UUID[];
	removedComponentItems: ProjectComponentItem[];
	roomHeight: number;
	lightsLevel: number;
}

export interface ProjectComponentItem {
	id: UUID;
	project: Project;
	quantity: number;
	netPrice: number;
	grossPrice: number;
	euroNetPrice: number;
	euroGrossPrice: number;
	componentItemData: ComponentItem;
}

export interface Project {
	id: UUID;
	name: string;
	projectIndex: number;
	contactEmail?: string;
	netPrice: number;
	grossPrice: number;
	euroNetPrice: number;
	euroGrossPrice: number;
	user: User | null;
	componentItems: ComponentItem[];
	projectComponentItems: ProjectComponentItem[];
	accessories: ProjectComponentItem[];
	componentItemsCount: number;
	status: ProjectStatus;
	isFavourite: boolean;
	updatedAt: string;
	createdAt: string;
	options: string;
	notes: ProjectNote[];
}

const PROJECT_NOTE_ENDPOINT = 'project-notes';

interface Filters extends ApiQueryOptions<Project> {
	statusFilter?: ProjectStatus;
}

class ProjectApi extends EntityApi<Project> {
	constructor() {
		super(QueryKey.projects);
	}

	async getPaginatedWithFilters({
		queryKey,
	}: QueryFunctionContext<[string, Filters]>): Promise<
		ApiFormattedResponseData<Project>
	> {
		const params = omitBy(
			{
				page: queryKey[1].page,
				sizePerPage: queryKey[1].sizePerPage,
				[`order[${String(queryKey[1].sort.key)}]`]: queryKey[1].sort?.order,
				search: trim(queryKey[1].search),
				searchFields: queryKey[1].searchFields.join(','),
				'filter[status]': queryKey[1].statusFilter,
			},
			(value) => !value
		);

		const response = await httpClient.get<ApiPaginatedResponse<Project>>(
			queryKey[0],
			{
				params,
			}
		);
		return {
			data: response.data.data,
			totalPages: response.data.totalPages,
		};
	}

	async getPaginatedAbandonedProjects({
		queryKey,
	}: QueryFunctionContext<[string, ApiQueryOptions<Project>]>): Promise<
		ApiFormattedResponseData<Project>
	> {
		const dateTo = formatDateForApi(moment().subtract(3, 'days'));

		const response = await httpClient.get<ApiPaginatedResponse<Project>>(
			queryKey[0],
			{
				params: {
					page: queryKey[1].page,
					sizePerPage: queryKey[1].sizePerPage,
					[`order[${String(queryKey[1].sort.key)}]`]: queryKey[1].sort?.order,
					search: queryKey[1].search,
					searchFields: queryKey[1].searchFields.join(','),
					'filter[status]': ProjectStatus.NOT_SENT,
					'dateTo[createdAt]': dateTo,
				},
			}
		);

		return {
			data: response.data.data,
			totalPages: response.data.totalPages,
		};
	}

	async createProjectNote({
		admin,
		project,
		note,
	}: {
		admin: Admin;
		project: Project;
		note: string;
	}) {
		const response = await httpClient.post<ProjectNote>(
			`${PROJECT_NOTE_ENDPOINT}`,
			{ admin: admin.id, project: project.id, note }
		);

		return response.data;
	}

	async updateProjectNote(note: Partial<ProjectNote>) {
		const response = await httpClient.patch<ProjectNote>(
			`${PROJECT_NOTE_ENDPOINT}/${note.id}`,
			note
		);

		return response.data;
	}

	async deleteProjectNote(noteId: string) {
		await httpClient.delete(`${PROJECT_NOTE_ENDPOINT}/${noteId}`);

		return true;
	}
}

export default new ProjectApi();
