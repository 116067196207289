import { HttpStatusCode } from 'axios';

export interface ApiError {
	error: string;
	message: ErrorCode | ErrorCode[] | string | string[];
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
	message: string;
}

export enum ErrorCode {
	GENERIC = 'genericError',
	USER_NOT_FOUND = 'userNotFound',
	USER_ALREADY_EXISTS = 'userAlreadyExists',
	DUPLICATED_EMAIL = 'duplicatedEmail',
	INVALID_PASSWORD = 'invalidPassword',
	PASSWORD_TOO_WEAK = 'passwordTooWeak',
	INVALID_REFRESH_TOKEN = 'invalidRefreshToken',
	REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
	REFRESH_TOKEN_MALFORMED = 'refreshTokenMalformed',
	INVALID_TOKEN = 'invalidToken',
	INVALID_HASH = 'invalidHash',
	MISSING_FIELD = 'missingField',
	DUPLICATED_NAME = 'duplicatedName',
}

export const getErrorMessage = (codes: ErrorCode[] | string[]) => {
	const codeToMessage = (code: ErrorCode | string) => {
		switch (code) {
			case ErrorCode.GENERIC:
				return 'Coś poszło nie tak! Spróbuj ponownie później.';
			case ErrorCode.USER_NOT_FOUND:
				return 'Taki email nie istnieje w systemie.';
			case ErrorCode.USER_ALREADY_EXISTS:
				return 'Podany adres email jest już zajęty!';
			case ErrorCode.INVALID_PASSWORD:
				return 'Hasło musi składać się z co najmniej 6 znaków, w tym jednej cyfry.';
			case ErrorCode.PASSWORD_TOO_WEAK:
				return 'Hasło musi składać się z co najmniej 6 znaków, w tym jednej cyfry.';
			case ErrorCode.DUPLICATED_EMAIL:
				return 'Podany adres email jest już zajęty!';
			case ErrorCode.INVALID_REFRESH_TOKEN:
				return 'Nieprawidłowy token!';
			case ErrorCode.REFRESH_TOKEN_EXPIRED:
				return 'Token wygasł!';
			case ErrorCode.REFRESH_TOKEN_MALFORMED:
				return 'Użytknownik nie został jeszcze aktytowany!';
			case ErrorCode.INVALID_TOKEN:
				return 'Podano nieprawidłowy token!';
			case ErrorCode.INVALID_HASH:
				return 'Podano nieprawidłowy token!';
			case ErrorCode.MISSING_FIELD:
				return 'Nie podano jednego z wymaganych pól!';
			case ErrorCode.DUPLICATED_NAME:
				return 'Podana nazwa jest zajęta!';
			default:
				return '';
		}
	};

	const errorMessages = codes.map((code) => codeToMessage(code));

	const message =
		errorMessages.filter((m) => !!m).join('. ') ||
		codeToMessage(ErrorCode.GENERIC);

	return message;
};
