import React, { CSSProperties } from 'react';

export const SpinnerLoader: React.FC<{ size?: number }> = ({ size = 40 }) => (
	<div className="spinner-chase" style={{ width: size, height: size }}>
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
		<div className="chase-dot" />
	</div>
);

interface Props {
	height?: CSSProperties['height'];
}

export const Loading: React.FC<Props> = ({ height }) => {
	return (
		<div id="preloader" style={height ? { height } : {}}>
			<div id="status">
				<SpinnerLoader />
			</div>
		</div>
	);
};
